const backgroundMusicVolume = 0.15;
const englishVoiceOverVolume = 1;
const greekVoiceOverVolume = 0.4;
const videoVolume = 0.5;

export {
    backgroundMusicVolume,
    englishVoiceOverVolume,
    greekVoiceOverVolume,
    videoVolume
};